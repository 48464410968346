<template>
	<footer id="page-footer" :class="mobileVerticalSubTabsClasses">
		<div class="container page-footer-container py-3 py-md-5">
			<div class="row">
				<div class="col-12 col-lg-6 order-lg-2">
					<div class="row">
						<div class="col-12 col-lg-6">
							<div class="flex-column d-flex footer-block pb-3 h-100">
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<strong>DJ boeken met garantie</strong>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<div>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'circle']" class="text-success" transform="grow-12" />
											<font-awesome-icon :icon="['fas', 'check']" style="color: #fff" transform="shrink-3" />
										</font-awesome-layers>
									</div>
									<strong>Niet goed, geld terug</strong>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<div>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'circle']" class="text-success" transform="grow-12" />
											<font-awesome-icon :icon="['fas', 'check']" style="color: #fff" transform="shrink-3" />
										</font-awesome-layers>
									</div>
									<strong>Gratis annuleren tot 14 dagen voor het feest</strong>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<div>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'circle']" class="text-success" transform="grow-12" />
											<font-awesome-icon :icon="['fas', 'check']" style="color: #fff" transform="shrink-3" />
										</font-awesome-layers>
									</div>
									<strong>100% match met jouw DJ</strong>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<div>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'circle']" class="text-success" transform="grow-12" />
											<font-awesome-icon :icon="['fas', 'check']" style="color: #fff" transform="shrink-3" />
										</font-awesome-layers>
									</div>
									<strong>Wij bestaan 10 jaar: 150,- korting</strong>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div class="flex-column d-flex footer-block pb-3 h-100">
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<strong>Snel navigeren naar</strong>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<router-link :to="{name: 'Home'}">Overzicht</router-link>
								</div>
								<div
									v-if="!isBookingByVenue"
									class="d-flex flex-row justify-content-start align-items-center footer-usp"
								>
									<router-link :to="{name: 'Prices'}">Prijzen</router-link>
								</div>
								<div
									v-if="!isBookingByVenue"
									class="d-flex flex-row justify-content-start align-items-center footer-usp"
								>
									<router-link :to="{name: 'PartyLocation'}">Feestlocatie</router-link>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<router-link :to="{name: 'Reviews'}">Klantervaringen</router-link>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<router-link :to="{name: 'PartyWishes'}">Feestwensen</router-link>
								</div>
								<div
									v-if="isDutch && !isBookingByVenue"
									class="d-flex flex-row justify-content-start align-items-center footer-usp"
								>
									<router-link :to="{name: 'Discounts'}">Kortingen bij leveranciers</router-link>
								</div>
								<div class="d-flex flex-row justify-content-start align-items-center footer-usp">
									<a target="_blank" href="https://calendly.com/thedjcompany/kennismaking">Maak een belafspraak</a>
								</div>
							</div>
						</div>
					</div>
					<div class="flex-column d-flex footer-block pb-3">
						<div class="d-none w-100 align-self-end mt-md-auto d-lg-flex flex-row align-items-center">
							<div class="mt-4 rounded w-100 d-flex flex-row align-items-center">
								<div class="p-2 rounded bg-light mr-1 flex-fill">
									<div class="w-100 text-center text-dark mb-2"><small>betaal veilig:</small></div>
									<div class="d-flex flex-row justify-content-center align-items-center">
										<img
											v-if="isBelgian"
											class="mx-1"
											style="max-height: 22px"
											src="~@/assets/images/betalen-bancontact.png"
										/>
										<img class="mx-1" style="max-height: 22px" src="~@/assets/images/betalen-ideal.png" />
										<img class="mx-1" style="max-height: 22px" src="~@/assets/images/betalen-mc.png" />
										<img class="mx-1" style="max-height: 22px" src="~@/assets/images/betalen-maestro.png" />
										<img class="mx-1" style="max-height: 22px" src="~@/assets/images/betalen-visa.png" />
										<img class="mx-1" style="max-height: 22px" src="~@/assets/images/betalen-paypal.png" />
									</div>
								</div>
								<div class="p-2 rounded bg-light flex-fill">
									<div v-if="isDutch" class="w-100 text-center text-dark mb-2"><small>bekend van:</small></div>
									<div v-if="isBelgian" class="w-100 text-center text-dark mb-2"><small>veilig boeken:</small></div>
									<div class="d-flex flex-row justify-content-center align-items-center">
										<img
											v-if="isDutch"
											class="mx-1"
											style="max-height: 22px"
											src="~@/assets/images/seenfrom-rtl4.png"
										/>
										<img v-if="isDutch" class="mx-1" style="max-height: 22px" src="~@/assets/images/seenfrom-tpw.png" />
										<img
											v-if="isBelgian"
											src="https://dashboard.trustprofile.com/banners/4000078/4017273/1652997600.svg"
											alt="Webwinkel Keurmerk en klantbeoordelingen"
											style="max-height: 25px"
											data-was-processed="true"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 order-lg-1">
					<div v-if="'status_booking__c' in booking" class="footer-speech-bubble mb-4 mb-lg-0">
						<div class="d-flex flex-column flex-md-row align-items-center justify-content-center">
							<img
								src="~@/assets/images/profile-tette.jpg"
								class="rounded-circle img-congrats mb-n3 mb-md-5 mr-md-n3"
							/>
							<div class="mb-md-n3">
								<div class="d-flex flex-column flex-md-row justify-content-start align-items-center mt-md-2">
									<img src="~@/assets/images/speech-pointer-r.png" class="quote-bubble-pointer" />

									<div class="rounded-pill quote-bubble position-relative mb-2">
										<p class="mb-0 pb-0 font-italic">
											<span v-if="isPreQuote"
												>"Bedankt voor je aanvraag! Vul de datum van jouw feest in en bereken direct jouw prijs."</span
											>

											<span v-if="isQuote"
												>"Bedankt voor je aanvraag. Wij garanderen de beste DJ voor jouw feest."</span
											>

											<span v-if="isOrderInProgress">
												<span v-if="!isPartyDatePast">
													<span v-if="!isPaid"
														>"Bedankt voor je bevestiging van jouw DJ. Maak de waarborgsom over om jouw DJ-boeking
														definitief te maken."</span
													>
													<span v-if="isPaid"
														>"Leuk dat je voor ons gekozen hebt! Wij kijken uit naar jouw feest op
														{{ partyDate }}"</span
													>
												</span>
												<span v-else>
													"Bedankt voor je vertrouwen en we horen graag weer van je bij een volgend feest."
												</span>
											</span>
										</p>
									</div>
								</div>
								<p class="quote-bubble-title text-md-left text-center ml-md-5">
									<strong v-if="isDutch">Tette<br /></strong>
									<span>Eigenaar <strong>The DJ Company</strong></span>
								</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mb-4 mb-lg-0">
							<div class="footer-block">
								<p><strong>Hulp of advies nodig?</strong></p>
								<div class="contact-options" itemscope="" itemtype="https://schema.org/Organization">
									<a
										target="_blank"
										:href="'tel:' + cleanPhoneNumber"
										class="d-flex flex-row justify-content-start align-items-center ml-2 mb-3"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #fff" transform="grow-26" />
											<font-awesome-icon
												:icon="['fas', 'mobile-alt']"
												style="color: #ff0000"
												class="text-primary"
												transform="grow-3"
											/>
										</font-awesome-layers>
										<div>
											<strong>{{ phoneNumber }}</strong>
											<small class="d-block"
												><font-awesome-icon :icon="['fas', 'user']" class="text-success mr-1" /> Nu Bereikbaar</small
											>
										</div>
									</a>
									<a
										v-if="isBelgian"
										target="_blank"
										href="https://wa.me/3233002797"
										class="d-flex flex-row justify-content-start align-items-center ml-2 mb-3"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #25d366" transform="grow-26" />
											<font-awesome-icon :icon="['fab', 'whatsapp']" style="color: #fff" transform="grow-3" />
										</font-awesome-layers>
										<div>
											<strong>+32 3 3002797</strong>
											<small class="d-block"
												><font-awesome-icon :icon="['fas', 'stopwatch']" class="text-success mr-1" /> Direct
												reactie</small
											>
										</div>
									</a>
									<a
										v-else
										target="_blank"
										href="https://wa.me/31854019438"
										class="d-flex flex-row justify-content-start align-items-center ml-2 mb-3"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #25d366" transform="grow-26" />
											<font-awesome-icon :icon="['fab', 'whatsapp']" style="color: #fff" transform="grow-3" />
										</font-awesome-layers>
										<div>
											<strong>085 - 40 19 438</strong>
											<small class="d-block"
												><font-awesome-icon :icon="['fas', 'stopwatch']" class="text-success mr-1" /> Direct
												reactie</small
											>
										</div>
									</a>

									<a
										:href="'mailto:' + infoEmailAddress"
										class="d-flex flex-row justify-content-start align-items-center ml-2 mb-3"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #ea4335" transform="grow-26" />
											<font-awesome-icon :icon="['far', 'envelope']" style="color: #fff" transform="grow-3" />
										</font-awesome-layers>
										<div>
											<strong>{{ infoEmailAddress }}</strong>
											<small class="d-block"
												><font-awesome-icon :icon="['fas', 'stopwatch']" class="text-success mr-1" /> Op dezelfde dag
												reactie</small
											>
										</div>
									</a>

									<a
										href="https://calendly.com/thedjcompany/kennismaking"
										target="_blank"
										class="d-flex flex-row justify-content-start align-items-center ml-2"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #006cff" transform="grow-26" />
											<font-awesome-icon :icon="['fal', 'calendar-clock']" style="color: #fff" transform="grow-3" />
										</font-awesome-layers>
										<div>
											<strong>Maak een belafspraak</strong>
											<small class="d-block"
												><font-awesome-icon :icon="['fas', 'stopwatch']" class="text-success mr-1" /> Plan online
												in</small
											>
										</div>
									</a>
								</div>
							</div>
						</div>
						<div class="col-sm-6 mb-4 mb-lg-0">
							<div v-if="!isBelgian" class="footer-block">
								<p><strong>Volg ons</strong></p>
								<div class="contact-options" itemscope="" itemtype="https://schema.org/Organization">
									<a
										itemprop="sameAs"
										href="https://www.facebook.com/thedjcompanynl/"
										target="_blank"
										class="d-flex flex-row justify-content-start align-items-center ml-2 mb-3"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #1877f2" transform="grow-26" />
											<font-awesome-icon :icon="['fab', 'facebook-f']" style="color: #fff" transform="grow-3" />
										</font-awesome-layers>
										<div>
											<strong>Facebook</strong>
											<small class="d-block">@thedjcompanynl</small>
										</div>
									</a>
									<a
										itemprop="sameAs"
										href="https://www.instagram.com/thedjcompanynl/"
										target="_blank"
										class="d-flex flex-row justify-content-start align-items-center ml-2 mb-3"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #e1306c" transform="grow-26" />
											<font-awesome-icon :icon="['fab', 'instagram']" style="color: #fff" transform="grow-3" />
										</font-awesome-layers>
										<div>
											<strong>Instagram</strong>
											<small class="d-block">@thedjcompanynl</small>
										</div>
									</a>
									<a
										href="https://www.youtube.com/channel/UCtVl0JX72_SrWBt-A-y17kg"
										target="_blank"
										class="d-flex flex-row justify-content-start align-items-center ml-2"
									>
										<font-awesome-layers class="fa-lg mr-4" fixed-width>
											<font-awesome-icon :icon="['fas', 'square']" style="color: #fff" transform="grow-26" />
											<font-awesome-icon :icon="['fab', 'youtube']" style="color: #ff0000" transform="grow-3" />
										</font-awesome-layers>
										<div>
											<strong>Youtube</strong>
											<small class="d-block">@thedjcompany01</small>
										</div>
									</a>
								</div>
							</div>
							<div v-else-if="isBelgian" class="footer-block">
								<strong>Ons adres</strong>
								<p>
									Lange Lozanastraat 142<br />
									2018 Antwerpen, België
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-3 order-lg-3">
					<div class="footer-block pb-3">
						<div class="d-lg-none w-100 align-self-end mt-md-auto d-flex flex-row align-items-center">
							<div class="rounded w-100 d-flex flex-row align-items-center">
								<div class="py-2 px-1 rounded bg-light mr-1 flex-fill">
									<div class="w-100 text-center text-dark mb-2"><small>betaal veilig:</small></div>
									<div class="d-flex flex-row justify-content-center align-items-center">
										<img
											v-if="isBelgian"
											class="mx-1"
											style="max-height: 20px"
											src="~@/assets/images/betalen-bancontact.png"
										/>
										<img class="mx-1" style="max-height: 20px" src="~@/assets/images/betalen-ideal.png" />
										<img class="mx-1" style="max-height: 20px" src="~@/assets/images/betalen-mc.png" />
										<img class="mx-1" style="max-height: 20px" src="~@/assets/images/betalen-maestro.png" />
										<img class="mx-1" style="max-height: 20px" src="~@/assets/images/betalen-visa.png" />
										<img class="mx-1" style="max-height: 20px" src="~@/assets/images/betalen-paypal.png" />
									</div>
								</div>
								<div class="py-2 px-1 rounded bg-light flex-fill">
									<div v-if="isDutch" class="w-100 text-center text-dark mb-2"><small>bekend van:</small></div>
									<div v-if="isBelgian" class="w-100 text-center text-dark mb-2"><small>veilig boeken:</small></div>
									<div class="d-flex flex-row justify-content-center align-items-center">
										<img
											v-if="isDutch"
											class="mx-1"
											style="max-height: 20px"
											src="~@/assets/images/seenfrom-rtl4.png"
										/>
										<img v-if="isDutch" class="mx-1" style="max-height: 20px" src="~@/assets/images/seenfrom-tpw.png" />
										<img
											v-if="isBelgian"
											src="https://dashboard.trustprofile.com/banners/4000078/4017273/1652997600.svg"
											alt="Webwinkel Keurmerk en klantbeoordelingen"
											style="max-height: 20px"
											data-was-processed="true"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="base-bc">
			<div class="container">
				<ol id="breadcrumbs" class="list-unstyled m-0" vocab="https://schema.org/" typeof="BreadcrumbList">
					<li class="d-inline-block">
						<a target="_blank" class="text-white" href="/"
							><span class="d-none">Home</span><i class="far fa-home"></i></a
						><i class="far fa-chevron-right"></i>
					</li>
					<li class="d-inline-block" property="itemListElement" typeof="ListItem">
						<span property="name">Mijn DJ Company</span><meta property="position" content="1" />
					</li>
				</ol>
			</div>
		</div>
		<div class="base-bar">
			<div class="container text-center">
				<ul id="menu-secondair-menu">
					<li>
						<a
							target="_blank"
							class="menu-item"
							:href="'https://thedjcompany.' + siteDomainExtention + '/over-the-dj-company/team/'"
						>
							Over Ons</a
						>
					</li>
					<li v-if="isDutch">
						<a target="_blank" class="menu-item" :href="'https://thedjcompany.' + siteDomainExtention + '/blog/'">
							Blog</a
						>
					</li>
					<li>
						<a
							target="_blank"
							class="menu-item"
							:href="'https://thedjcompany.' + siteDomainExtention + '/over-the-dj-company/dj-vacature/'"
						>
							DJ Vacature</a
						>
					</li>
					<li>
						<a target="_blank" class="menu-item" :href="'https://thedjcompany.' + siteDomainExtention + '/faq/'">
							Veelgestelde Vragen</a
						>
					</li>
					<li>
						<a
							target="_blank"
							class="menu-item"
							:href="'https://thedjcompany.' + siteDomainExtention + '/over-the-dj-company/algemene-voorwaarden/'"
						>
							Algemene Voorwaarden</a
						>
					</li>
					<li>
						<a
							target="_blank"
							class="menu-item"
							:href="'https://thedjcompany.' + siteDomainExtention + '/over-the-dj-company/privacy/'"
						>
							Privacy en Cookie Statement</a
						>
					</li>
					<li>
						<a
							target="_blank"
							class="menu-item"
							:href="'https://thedjcompany.' + siteDomainExtention + '/over-the-dj-company/contact/'"
						>
							Contact</a
						>
					</li>
				</ul>
			</div>
		</div>
		<div class="base-bar" style="background-color: #eaeaea">
			<div class="d-flex justify-content-center align-items-center">
				<div>
					<img src="~@/assets/images/logo-mijndjcompany.png" style="max-width: 120px" class="border-right pr-2 mr-2" />
				</div>
				<div>
					<div class="rating text-dark">9.2 <small class="rating-outof">/ 10</small></div>
					<div class="stars">
						<font-awesome-icon :icon="['fas', 'star']" />
						<font-awesome-icon :icon="['fas', 'star']" />
						<font-awesome-icon :icon="['fas', 'star']" />
						<font-awesome-icon :icon="['fas', 'star']" />
						<font-awesome-icon :icon="['fas', 'star-half-alt']" /><br />
						<small class="rating-lbl">
							<a target="_blank" :href="'https://thedjcompany.' + siteDomainExtention + '/klantervaringen/'">
								<span itemprop="ratingCount">9000+</span> ervaringen
							</a>
						</small>
					</div>
				</div>
			</div>
		</div>

		<div class="base-bar" style="padding-bottom: 100px">
			<div class="container text-center">
				<p>© 2011 - {{ currentYear }} The DJ Company</p>
			</div>
		</div>
	</footer>
</template>
<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon, FontAwesomeLayers} from "@fortawesome/vue-fontawesome";
export default {
	name: "Footer",
	computed: {
		...mapGetters({
			booking: "getBooking",
			customPhoneNumber: "getCustomPhoneNumber",
			isPreQuote: "isPreQuote",
			isQuote: "isQuote",
			isOrderInProgress: "isOrderInProgress",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
			infoEmailAddress: "getInfoEmailAddress",
			isBookingByVenue: "isBookingByVenue",
		}),
		daysLeft: function () {
			return this.$date(this.booking.Party_Date__c).diff(this.$date(), "days");
		},
		isPaid: function () {
			return "Down_Payment_Paid_Date__c" in this.booking || this.booking.Down_Payment_Amount2__c == 0;
		},
		finalInvoicIsPaid: function () {
			return "Invoices__r" in this.booking && "PaidDate__c" in this.booking.Invoices__r.records[0];
		},
		partyDate: function () {
			return this.$date(this.booking.Party_Date__c).locale("nl").format("dddd, D MMMM YYYY");
		},
		isPartyDatePast: function () {
			return this.daysLeft < 0;
		},
		mobileVerticalSubTabs: function () {
			return !!this.$route.meta?.subNav?.mobileVertical;
		},
		mobileVerticalSubTabsClasses: function () {
			return this.mobileVerticalSubTabs ? "mobile-vertical-tabs-spacing" : "no-mobile-vertical-tabs";
		},
		phoneNumber: function () {
			return this.isBelgian ? "+32 3 3002797" : this.customPhoneNumber;
		},
		cleanPhoneNumber: function () {
			if (this.isBelgian) {
				return "003233002797";
			}
			return this.customPhoneNumber.replace(/-|\s/g, "");
		},
		siteDomainExtention: function () {
			return this.isBelgian ? "be" : "nl";
		},
	},
	components: {
		FontAwesomeIcon,
		FontAwesomeLayers,
	},
	data() {
		return {
			currentYear: new Date().getFullYear(), // 2020
		};
	},
};
</script>

<style lang="scss">
.footer-speech-bubble {
	@include media-breakpoint-down(md) {
		border-bottom: 1px solid #17aaf3;
	}
	.img-congrats {
		max-width: 120px;
		border: 5px solid #0096e1;
		@include media-breakpoint-up(md) {
			max-width: 150px;
		}
	}
	.quote-bubble {
		background: #fff;
		padding: 10px 20px;
		color: #000;
		border-radius: 3px;
		text-align: left !important;
		@include media-breakpoint-up(md) {
			padding: 15px;
		}
	}
	.quote-bubble-pointer {
		width: 30px;
		transform: rotate(90deg) !important;
		@include media-breakpoint-up(md) {
			transform: none !important;
			margin-right: -7px;
		}
	}
}
footer {
	background: $tdc-blue;
	color: #fff;
	display: block;
	.row {
		position: relative;
	}
	.schema-row {
		position: relative;
		.schema-left,
		.schema-right {
			width: 50%;
			float: left;
			height: 90px;
		}
		.schema-left {
			text-align: right;
			img {
				width: 70%;
				margin-right: 10px;
				padding: 10px 10px 10px 0;
				border-right: 1px solid #cfcfcf;
			}
		}
		.schema-right {
			text-align: left;
		}
	}
	.page-footer-container {
		@include media-breakpoint-down(md) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	.footer-block {
		.speech-bubble {
			margin-bottom: 20px;
			background-color: #0096e1;
			color: #fff;
			border: none;
			padding-top: 50px;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}
		.rounded-circle {
			border: 5px solid #0096e1;
			position: relative;
			z-index: 2;
			background: #fff;
			margin: 0 auto -40px auto;
			max-width: 130px;
			height: auto;
		}
		a {
			color: #fff;
		}
		.contact-options {
			.contact-option {
				margin: 5px 0px;
				border-bottom: 1px solid #17aaf3;
				padding: 5px 0;
				@include media-breakpoint-down(xs) {
					text-align: center;
					border: none;
					display: inline-block;
					padding: 7px;
				}
				i {
					margin-top: 2px;
					font-size: 20px !important;
					position: absolute;
					@include media-breakpoint-down(xs) {
						position: relative;
					}
				}
			}
			.contact-option-lbl {
				padding-left: 0px;
				@include media-breakpoint-down(xs) {
					display: none;
					text-align: center;
				}
				@include media-breakpoint-up(sm) {
					padding-left: 35px;
				}
				@include media-breakpoint-up(lg) {
					padding-left: 35px;
				}
				small {
					display: block;
				}
			}
		}
	}
	.search-well-footer {
		background-color: darken($tdc-blue, 9%);
		p {
			color: #000;
		}
		#auto-search-footer {
			font-size: 16px;
			@include media-breakpoint-up(md) {
				font-size: 16px;
			}
		}
		.tt-hint {
			font-size: 16px;
			@include media-breakpoint-up(md) {
				font-size: 16px;
			}
		}
	}
	p {
		@include media-breakpoint-down(sm) {
			overflow-wrap: break-word;
			word-wrap: break-word;
			-ms-word-break: break-all;
			word-break: break-all;
			word-break: break-word;
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			-webkit-hyphens: auto;
			hyphens: auto;
		}
	}
	h1,
	h2,
	h3,
	h4 {
		color: #fff;
		strong {
			color: #fff;
		}
	}
	.base-bc {
		text-align: center;
		padding: 20px 0;
		background-color: darken($tdc-blue, 2.5%);
		p {
			font-size: 14px;
			margin: 0;
			a {
				color: #fff;
			}
		}
		.bc-div {
			padding: 0 5px;
			position: relative;
			display: inline-block;
			font-size: 11px;
		}
		.fa-chevron-right {
			padding: 0 8px;
			position: relative;
			display: inline-block;
			font-size: 11px;
		}
		.breadcrumb_last {
			color: #fff;
		}
	}
	.base-bar {
		padding: 10px 0;
		@include media-breakpoint-up(sm) {
			padding: 20px 0;
		}
		background-color: $tdc-body-color;
		ul#menu-secondair-menu {
			> li {
				display: inline-block;
				list-style: none;
				padding: 0;
				margin: 0;
				border-right: 1px solid #cfcfcf;
				padding: 5px 8px;
				&:last-child {
					border-right: none;
				}

				> a {
					display: block;
					margin: 0px;
					padding: 0 4px;
					font-size: 14px;
				}
			}
		}
		ul {
			margin-bottom: 0;
			li {
				list-style: none;
			}
		}
		p {
			color: $tdc-blue;
		}
		p {
			font-size: 14px;
		}
	}
	a {
		&:hover {
			text-decoration: none;
		}
	}
	.handy-link,
	.footer-usp {
		border-bottom: 1px solid #17aaf3;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
</style>
